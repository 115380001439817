<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form">
                    <v-card :elevation="1" color="grey lighten-3">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-card-text class="py-0">
                                    <v-row dense>
                                        <v-col cols="5">
                                            <v-row no-gutters>
                                                <v-col cols="2">Form Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="date"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="2" class="text-center">To Date</v-col>
                                                <v-col cols="4">
                                                    <v-menu>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                outlined
                                                                hide-details
                                                                v-model="date"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="date"></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn height="26px" dark block color="light-blue darken-2">Search</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="pt-2">
            <v-col cols="12">
                <v-btn small color="grey lighten-2">Print</v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="6">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-left">Total Sales</td>
                                <td class="text-right">306880.02</td>
                            </tr>
                            <tr>
                                <td class="text-left">Customer Payment Received</td>
                                <td class="text-right">110800.00</td>
                            </tr>
                            <tr>
                                <td class="text-left">Cash Received</td>
                                <td class="text-right">130000.00</td>
                            </tr>
                            <tr>
                                <td class="text-left">Withdraw from Bank</td>
                                <td class="text-right">3600.00</td>
                            </tr>
                            <tr>
                                <td class="text-left">Supplier Payment Received</td>
                                <td class="text-right">0.00</td>
                            </tr>
                            <tr>
                                <td class="text-right font-weight-bold">Total Cash In</td>
                                <td class="text-right font-weight-bold">0.00</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'PresentEntry',
    data: ()=> ({
        date: new Date().toISOString().substr(0, 10),
    }),
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px;
       top: -3px;
    }
    .theme--light.v-data-table {
        border: 1px solid #000000;
        border-radius: unset !important;
        thead {
            tr {
                th {
                    border-bottom: 1px solid #000000 !important;
                    font-size: 14px;
                    padding: 0 10px;
                    color: #000 !important;
                    font-weight: normal !important;
                    height: auto !important;
                }
                th:first-child {
                    border-right: 1px solid #000000;
                }
            }
        }
        tbody {
            tr {
                td {
                    height: 20px !important;
                    padding: 1px 10px !important;
                    font-size: 12px !important;
                    border: none !important;
                    text-align: center;
                }
            }
        }
    }
</style>